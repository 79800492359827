import React, { useState, useEffect } from 'react';

import './App.css';
import './antd.css';
import * as qs from 'query-string';


import { Button, Checkbox, Form, Input, message } from 'antd';
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'


function Logout() {
  const urlParams = qs.parse(window.location.search);
  const params = useParams();

  useEffect(() => {
    store.remove("pubkit_auth");
    store.remove("pubkit_user");
    store.remove("pubkit_role");
    window.location.href = "/";
  }, []);


  return (
    <div></div>
  );
}

export default Logout;