import './App.css';
import './antd.css';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
  MailOutlined,
  FormOutlined,
  CommentOutlined,
  ContainerOutlined,
  StopOutlined,
  HighlightOutlined,
  ProfileOutlined,
  LinkOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  TeamOutlined
} from '@ant-design/icons';

import {
  Layout,
  Menu,
  Col,
  notification,
  Button,
  DatePicker,
  Checkbox,
  Anchor,
  Card,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  Table,
  Modal,
  message,
  Space
} from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot,reportApiRoot, myBucket, AWS } from "./Config";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as qs from 'query-string';
import moment from 'moment'

const { Link } = Anchor;
const { Title } = Typography;
var pageTitle = "Submitted Applications";
var currentProjectId = null;
var tempObject = {};

let restFul = axios.create({
  baseURL: apiRoot,
  timeout: 1000
});

restFul.interceptors.response.use((response) => response, (error) => {
  console.log(error);
  message.info(error.message);
  if (error.response.status == 401) {
    window.location.href = "/";
  }
});

const { Header, Sider, Content, Footer } = Layout;
const { Option } = Select;
const { TextArea } = Input;

var assignProjectProps = {};
var textAreaText = "";

const languageCompetency = ["Beginner/GCSE", "Proficient/A level", "Fluent/Degree", "Native Speaker"];
const subjectCompetency = ["Primary", "Secondary", "Postsecondary"];
const ellCompetency = ["Primary", "Secondary", "Postsecondary"];


export default function Manager(props) {
  const [form] = Form.useForm();
  const [composeEmailForm] = Form.useForm();
  const [newProjectForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const params = useParams();
  const [freelancers, setFreelancers] = useState([]);
  const [freelancerApplications, setFreelancerApplications] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedFreelancerIds, setSelectedFreelancerIds] = useState([]);
  const [projects, setProjects] = useState([]);
  const [assignProjectModalVisible, setAssignProjectModalVisible] = useState(false);
  const [emailTemplateBody, setEmailTemplateBody] = useState({"body":"", "emailSubject":""});
  const [selectedFile, setSelectedFile] = useState(null);
  const [showProjectFileUpload, setShowProjectFileUpload] = useState("none");
  const [currentObject, setCurrentObject] = useState({});
  const [projectFreelancersModal, setProjectFreelancersModal] = useState(false);
  const [projectFreelancers, setProjectFreelancers] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [applicationStatusModal, setApplicationStatusModal] = useState(false);
  const [projectStatusModal, setProjectStatusModal] = useState(false);
  const [projectTasks, setProjectTasks] = useState([]);
  const [projectTaskOptions, setProjectTaskOptions] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [freelanceProjects, setFreelanceProjects] = useState([]);
  const [interestList, setInterestList] = useState([]);
  const [stemList, setStemList] = useState([]);
  const [nonStemList, setNonStemList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [citationList, setCitationList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [softwareList, setSoftwareList] = useState([]);
  const [ellList, setEllList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  //const [teamList, setTeamList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [userNotesModalShow, setUserNotesModalShow] = useState(false);
  const [userNotesModalMessage, setUserNotesModalMessage] = useState("");
  const [freelancerEmailOptions, setFreelancerEmailOptions] = useState([]);
  const [correspondenceBody, setCorrespondenceBody] = useState("");
  const [currentProject, setCurrentProject] = useState({ "name": "" });
  const [currentTask, setCurrentTask] = useState({ "name": "" });
  const [currentFreelancer, setCurrentFreelancer] = useState({ "fullName": "" });
  const [listProjects, setListProjects] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [showGroupMapping, setShowGroupMapping] = useState(false);
  const [showDivisionMapping, setShowDivisionMapping] = useState(false);
  const [freelancerScore, setFreelancerScore] = useState([]);
  const [showFreelancerScore, setShowFreelancerScore] = useState(false);
  const [freelancerDivision, setFreelancerDivision] = useState(false);
  const [freelancerSkill, setFreelancerSkill] = useState(false);
  const [contactFeatureBox, setContactFeatureBox] = useState("none");
  

  const urlParams = qs.parse(window.location.search);

  const cleanArray = (input) =>
  {
    input = input.filter(function (el) { return el != null; });
    input = input.filter(function (el) { return el != ""; });
    return input;
  }

  const downloadS3File = (record) => {
    if (record.filePath == undefined) record["filePath"] = record["filepath"];
    const url = myBucket.getSignedUrl('getObject', { Key: record.filePath, Expires: (60 * 5), ResponseContentDisposition: 'attachment; filename ="' + record["filename"] + '"'
  });
    window.location.href = url;
  }

  const onCheckboxChange = (form, e) => {
    assignProjectProps[form] = e.target.checked;
  }

  const formatDate = (data, field) => {
    console.log("data", data);
    for (var i = 0; i < data.length; i++) {
      if (data[i][field] == undefined) continue;
      data[i][field] = moment(data[i][field]).format("lll");
    }
    return data;
  }

  const freelanceProjectManagerDecisions = (record, value) => {
    setCurrentObject(record);
    setProjectStatusModal(true);
  }

  const changeApplicationStatus = (values) => {
    console.log(values);
    setApplicationStatusModal(false);
    axios.put(`${apiRoot}submissions/freelancer/api/user-skills/${currentObject.fuzzyId}/update`, values, { headers }).then(res => {
      message.info(res.data.message, 10);
      setTimeout(function () { window.location.href = "/manager/projects?status=Completed" }, 5000);
    });
  }

  const changeProjectStatus = (values) => {
    if (values["budgetActual"] == null) { values["budgetActual"] = 0 };
    values["budgetActual"] = (values["budgetActual"] * 1);
    setProjectStatusModal(false);
    axios.put(`${apiRoot}submissions/freelancer/api/freelance-project/${currentObject.fuzzyId}/update`, values, { headers }).then(res => {
      console.log(res.data);
      message.info(res.data.message, 10);
    });
  }

  const managerSelectFreelancerForProject = (e, values) => {
    console.log(e);
    console.log(values);
    var payload = { "managerDecision": e };
    axios.put(`${apiRoot}submissions/freelancer/api/freelance-project-users/${values.fuzzyId}/decision`, payload, { headers }).then(res => {
      console.log(res.data);
      window.location.href = window.location.href;
    });
  }

  const saveUserNotes = (values) => {
    console.log(values);
    axios.post(`${apiRoot}submissions/freelancer/api/save-user-notes/${currentObject["userFuzzyId"]}`, values, { headers }).then(res => {
      console.log(res.data);
      setUserNotesModalShow(false);
    });
  }

  const showGroupDialog = (record) => {
    setCurrentObject(record);
    console.log(record);
    setShowGroupMapping(true);
    axios.get(`${apiRoot}submissions/freelancer/api/find-skills?user-id=${record["id"]}&genre=interest`, { headers }).then(res => {
      console.log(res.data);
      setFreelancerSkill(res.data);
    });
  }


  const showDivisionDialog = (record) => {
    setCurrentObject(record);
    console.log(record);
    setShowDivisionMapping(true);
    axios.get(`${apiRoot}submissions/freelancer/api/division-user/${record["id"]}`, { headers }).then(res => {
      console.log(res.data);
      setFreelancerDivision(res.data);
    });
  }

  const showUserNotes = (record) => {
    setCurrentObject(record);
    console.log(record);
    axios.get(`${apiRoot}submissions/freelancer/api/freelance-project-users/${record.userFuzzyId}/kpis`, { headers }).then(res => {
      console.log(res.data.kpis);
      if (res.data.kpis == null) res.data.kpis = [];
      if (res.data.userNotes == null) res.data.userNotes = [];
      var messageList = "";
      var messageListTable = "";
      for (var i = 0; i < res.data.kpis.length; i++) {
        messageListTable += "<tr><td>" + moment(res.data.kpis[i]["created_at"]).format("lll") + "</td><td>" + res.data.kpis[i]["remarks"] + "</td><td>" + res.data.kpis[i]["average_rank"] + "</td><td> " + res.data.kpis[i]["managerName"] + "</td></tr>";
      }

      if (res.data.kpis.length != 0) 
      {
        messageList += "<div class='dialogHeader'>Recent Project Based KPI Comments</div>";
        messageList += "<table class='table table-bordered'><thead><tr><th>Date</th><th>Remarks</th><th>Rank</th><th>Manager</th><thead><tbody>";
        messageList += messageListTable;
        messageList += "</tbody></table>";
      }
      
      messageListTable = "";
      for (var i = 0; i < res.data.userNotes.length; i++) {
        messageListTable += "<tr><td>" + moment(res.data.userNotes[i]["createdAt"]).format("lll") + "</td><td>" + res.data.userNotes[i]["notes"] + "</td><td>" + res.data.userNotes[i]["sentiment"] + "</td><td>" + res.data.userNotes[i]["managerName"] + "</td></tr>";
      }

      if (res.data.userNotes.length != 0)
      {
        messageList += "<div class='dialogHeader'>General Notes From Managers</div>";
        messageList += "<table class='table table-bordered'><thead><tr><th>Date</th><th>Remarks</th><th>Rank</th><th>Manager</th><thead><tbody>";
        messageList += messageListTable;
        messageList += "</tbody></table>";
      }

      if (messageList.length == 0) messageList = "<div>No remarks found for the user.</div>";
      setUserNotesModalMessage(messageList);
      setUserNotesModalShow(true);
    });
  }


  const massageFreelancerData = (res) => {
    var freelancers = res.data["freelancers"];
    const rates = res.data["rates"];
    const lastProjectRates = res.data["lastProjectRates"];
    const userSkills = res.data["userSkills"];
    for (var i = 0; i < freelancers.length; i++)
    {
      console.log("1,"+freelancers[i]["averageScore"]);
      if (freelancers[i]["ndaAt"] !== undefined) freelancers[i]["ndaAt"] = "Yes";
      var userRate = lastProjectRates.find(item => item.userId == freelancers[i]["id"]);
      if(userRate==null)
      {
        userRate = rates.find(item => item.userId == freelancers[i]["id"]);
      }
      console.log(userRate);
      if (userRate != null) {
        freelancers[i]["price"] = ((userRate["price"]!=null) ? (Math.round(userRate["price"] * 100) / 100) : "");
        freelancers[i]["unit"] = userRate["unit"];
        freelancers[i]["currency"] = userRate["currency"];
      }
      const userSkill = userSkills.filter(item => item.userId == freelancers[i]["id"]);
      var userSkillNames = []
      if (userSkill.size != 0)
      {
        userSkillNames = userSkill.map(function (value, index) { return value["name"]; })
        freelancers[i]["score"] = userSkill[0]["score"];
        console.log(freelancers[i]["score"]);
      }
      freelancers[i]["skills"] = userSkillNames.join(", ");
      freelancers[i]["averageScore"] = ((freelancers[i]["averageScore"]!=null) ? (Math.round(freelancers[i]["averageScore"] * 100) / 100) : 0);
    }
    return [...freelancers];
  }

  const changeCurrentObject = (record) => {
    console.log(record);
    setCurrentObject(record);
    setApplicationStatusModal(true);
  }

  var freelanceAuth = store("pubkit_auth");
  var headers = { ...freelanceAuth };

  useEffect(() => {
    console.log("params", params);
    console.log("urlParams", urlParams);
    console.log(freelanceAuth);

    if (urlParams.status == "evaluation-form") {
      axios.get(`${apiRoot}submissions/freelancer/public/kpis?page=0&app=freelance`, { headers }).then(res => {
        console.log(res.data)
        setKpis(res.data);
      });

      axios.get(`${apiRoot}submissions/freelancer/api/freelance-project-users/${urlParams["fuzzyId"]}/info`, { headers }).then(res => {
        console.log(res.data)
        if (res.data["freelancer"] == undefined) {
          return;
        }
        setCurrentProject(res.data["project"]);
        setCurrentTask(res.data["task"]);
        setCurrentFreelancer(res.data["freelancer"]);
      });

    }

    if (urlParams.status == "new-project") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=Created`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        setFreelanceProjects(res.data);
      });

      axios.get(`${apiRoot}submissions/freelancer/public/skills/genre/skill`, { headers }).then(res => {
        var skillList = res.data.filter(record => record["genre"] == "skill");
        skillList = skillList.sort( (a,b) => (a.name < b.name) ?  -1 : 1 );
        setSkillList(skillList);
        console.log(skillList);
      });

    }

    if (urlParams.status == "Assigned") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=Assigned`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        setFreelancers(res.data);
      });
    }


    if (urlParams.status == "Evaluated") {
      console.log(headers);
      axios.get(`${apiRoot}submissions/freelancer/api/project/list?page=0&type=Evaluated`, { headers }).then(res => {
        setFreelancers(res.data);
      });
    }


    if (urlParams.status == "Completed") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=Completed`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        setFreelancers(res.data);
      });
    }

    if (urlParams.status == "projects-all") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        setListProjects(res.data);
      });
    }


    if (params["page"] == "applications") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/applications?page=0&status=` + urlParams["status"];
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data);
        var freelancers = res.data["application"];
        const skills = res.data["skills"];
        for (var i = 0; i < freelancers.length; i++) {
          const userSkill = skills.filter(item => item["user_id"] == freelancers[i]["id"]);
          freelancers[i]["interest"] = [];
          if(userSkill==null) continue;
          userSkill.map(eachSkill => {
            freelancers[i]["interest"].push(eachSkill["name"]);
          });
          freelancers[i]["interest"] = freelancers[i]["interest"].join(", ");
        }
        setFreelancerApplications(freelancers);
      });
    }


    if (params["page"] == "freelancers") {
      setFreelancers([]);
      axios.post(`${apiRoot}submissions/freelancer/api/find-for-invite?page=0`, { "status": "APPROVED" }, { headers }).then(res => {
        setFreelancers(massageFreelancerData(res));
      });

      restFul.get(`submissions/freelancer/api/email-templates?type=freelance`, { headers }).then(res => {
        setEmailTemplates(res.data);
      });
    }

    if (urlParams.status == "selected") {

      axios.get(`${apiRoot}submissions/freelancer/public/skills`, { headers }).then(res => {
        setInterestList(res.data.filter(record => record["genre"] == "interest"));
        setNonStemList(res.data.filter(record => record["genre"] == "non-stem"));
        setStemList(res.data.filter(record => record["genre"] == "stem"));
        setSubjectList(res.data.filter(record => record["genre"] == "subject"));
        setCitationList(res.data.filter(record => record["genre"] == "citation"));
        setSkillList(res.data.filter(record => record["genre"] == "skill"));
        setSoftwareList(res.data.filter(record => record["genre"] == "software"));
        setLanguageList(res.data.filter(record => record["genre"] == "language"));
        setEllList(res.data.filter(record => record["genre"] == "ell"));
      });

      axios.get(`${apiRoot}submissions/freelancer/public/countries`, { headers }).then(res => {
        res.data = res.data.sort(function (a, b) { return a.name > b.name ? 1 : -1; });
        //res.data.unshift({ "name": "United States" }, { "name": "United Kingdom" }, { "name": "India" });
        setCountryList(res.data);
      });
      let divisionPayload = {
        "table": "divisions",
        "where": {
            "accounts_wip": 1
        },
        "offset":0,
        "length":1000
    }
      axios.post(`${reportApiRoot}reportsmicro/reports/any/many`, divisionPayload ,{ headers }).then(res => {
        
        setDivisionList(res.data);
      });
      // axios.get(`${apiRoot}submissions/freelancer/public/teams`, { headers }).then(res => {
      //   setTeamList(res.data);
      // });


    }

    const notificationKey = `notification-open-${Date.now()}`;
    const btn = (<Button type="primary" size="small" onClick={(e) => window.location.href = `projects?status=Completed`}>OK</Button>);

    setInterval(() => {
      axios.get(`${apiRoot}submissions/freelancer/api/project/for-evaluation-by-user`, { headers }).then(res => {
        if (res.data.length != 0) {
          notification.open({
            message: 'Please complete the evaluation form!',
            btn,
            notificationKey
          });
        }
      });
    }, (30 * 1000));
  }, []);


  const handleFileInput = (e) => {
    console.log("handleFileInput is triggred");
    setSelectedFile(e.target.files[0]);
  }

  const uploadFile = (file) => {
    console.log("Upload is being called");
    message.info("Please wait while upload is in progress");
    var uploadParams = { Key: "/uploads/" + new Date().getTime() };
    uploadParams.Body = file;
    myBucket.upload(uploadParams, function (err, data) {
      if (err) {
        console.log("Error", err);
      } if (data) {
        console.log("Upload Success", data.Location);
        var freelanceAuth = store("pubkit_auth");
        var headers = { ...freelanceAuth };
        data["projectId"] = currentObject["projectId"];
        data["taskId"] = currentObject["taskId"];
        data["filename"] = file.name;
        data["type"] = file.type;
        data["size"] = file.size;
        data["filepath"] = uploadParams["Key"];
        data["genre"] = "freelance-project-users";
        axios.post(`${apiRoot}submissions/freelancer/api/project/${currentObject["projectId"]}/upload/file`, data, { headers }).then(res => {
          console.log(res.data)
          window.location.href = window.location.href;
        });
      }
    });
  }


  const menuClicked = (e) => {
    window.location.href = '/manager/' + e.key;
  };

  const sendFreelancerInvitation = (e) => {
    assignProjectProps["freelancers"] = selectedFreelancerIds;
    assignProjectProps["freelanceProjectId"] = (assignProjectProps["freelanceProjectId"] * 1);
    assignProjectProps["websiteUrl"] = window.location.origin;
    console.log(assignProjectProps);
    axios.post(`${apiRoot}submissions/freelancer/api/project/send-invites`, assignProjectProps, { headers }).then(res => {
      console.log(res.data)
      setAssignProjectModalVisible(false);
      message.success('Invitation has been sent to freelancers', 50);
    });
  };

  const assignProjectModalCancel = () => {
    setAssignProjectModalVisible(false);
  };


  const showAssignProjectModalVisibleIfSelected = (e) => {
    if (selectedFreelancerIds.length == 0) {
      message.info('Please select a few freelancers.');
      return;
    }
    setAssignProjectModalVisible(true);
  }

  const copySelectedEmails = () => {
    console.log(freelancers);
    const selectedFreelancers = freelancers.filter(x => selectedFreelancerIds.includes(x.id));
    var emailAddresses = [];
    selectedFreelancers.forEach(function (each) {
      emailAddresses.push(each["full_name"] + "<" + each["email"] + ">");
    });
    navigator.clipboard.writeText(emailAddresses.join(";"));
    message.info("Email addresses are copied to the clipboard.");
  }

  const copyCurrentEmail = (record) =>
  {
    navigator.clipboard.writeText(record["email"]);
    message.info("Email address is copied to the clipboard.");
  }

  const searchProjects = (newValue) => {
    setProjects([]);
    if (newValue) {
      fetchProjects("", newValue, setProjects);
    }
  };

  const searchFreelanceProjects = (newValue) => {
    setFreelanceProjects([]);
    if (newValue) {
      fetchFreelanceProjects("", newValue, setFreelanceProjects);
    }
  };


  const changeKeyValue = (newValue, e) => {
    assignProjectProps[newValue] = e;
    console.log(assignProjectProps);
  };

  const onEmailTemplateSelect = (newValue, e) => {
    assignProjectProps[newValue] = e;
    console.log(assignProjectProps);
    var payload = {};
    payload["emailTemplateId"] = (assignProjectProps["emailTemplateId"] * 1);
    payload["freelanceProjectId"] = (assignProjectProps["freelanceProjectId"] * 1);
    payload["freelanceProjectId"] = (assignProjectProps["freelanceProjectId"] * 1);
    if(assignProjectProps["startDate"]!=undefined) payload["startDate"] = moment(assignProjectProps["startDate"]).format("YYYY-MM-DD");
    if(assignProjectProps["dueDate"]!=undefined) payload["dueDate"] = moment(assignProjectProps["dueDate"]).format("YYYY-MM-DD");
    if(assignProjectProps["editingStyle"]!=undefined) payload["editingStyle"] = assignProjectProps["editingStyle"];
    if(Array.isArray(assignProjectProps["skillId"]))
    {
      payload["service"] = skillList.filter(item => item["id"]==assignProjectProps["skillId"][0])[0]["name"];
    }

    axios.post(`${apiRoot}submissions/freelancer/api/freelance-projects/${payload["freelanceProjectId"]}/compose-email`, payload, { headers }).then(res => {
      console.log(res.data);
      if(res.data["email"]!=undefined)
      {
        assignProjectProps["body"] = res.data["email"]["emailBody"];
        assignProjectProps["body"] = assignProjectProps["body"].replaceAll("{#", "{{").replaceAll("#}","}}");
        
        assignProjectProps["emailSubject"] = res.data["email"]["emailSubject"]+"";
        assignProjectProps["emailSubject"] = assignProjectProps["emailSubject"].replaceAll("{#", "{{").replaceAll("#}","}}");
        setEmailTemplateBody({"body":assignProjectProps["body"], "emailSubject": assignProjectProps["emailSubject"]});
        composeEmailForm.setFieldsValue({"emailSubject": assignProjectProps["emailSubject"]});
      }
    });
  }



  const fetchProjects = (type, value, callback) => {
    axios.get(`${apiRoot}submissions/freelancer/api/project/search?q=` + value + "&type=" + type, { headers }).then(res => {
      console.log(res.data);
      var projectList = [];
      for (var i = 0; i < res.data.length; i++) {
        projectList.push({ "value": res.data[i]["id"], "text": res.data[i]["name"] });
      }
      callback(projectList);
    });
  }

  const fetchFreelanceProjects = (type, value, callback) => {
    axios.get(`${apiRoot}submissions/freelancer/api/freelance-project/search?q=` + value + "&type=" + type, { headers }).then(res => {
      console.log(res.data);
      var projectList = [];
      for (var i = 0; i < res.data.length; i++) {
        projectList.push({ "value": res.data[i]["freelanceProjectId"], "text": res.data[i]["projectName"] + "~" + res.data[i]["taskName"] });
      }
      callback(projectList);
    });
  }


  const projectOptions = projects.map((d) => <Option key={d.value}>{d.text}</Option>);
  const emailTemplateOptions = emailTemplates.map((d) => <Option key={d.id}>{d.name}</Option>);
  const freelanceProjectOptions = freelanceProjects.map((d) => <Option key={d.value}>{d.text}</Option>);

  console.log("Default selected", params["page"] + "?status=" + urlParams['status']);


  const showUserScores = (record) => {
    console.log(record);
    axios.get(`${apiRoot}submissions/freelancer/api/user/${record.userFuzzyId}/user-kpi-scores`, { headers }).then(res => {
      console.log(res.data["user_scores"]);
      setFreelancerScore(res.data["user_scores"]);
      setShowFreelancerScore(true);
    });
  }




  return (
    <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
      
      <Layout className="site-layout">
        <Sider trigger={null} collapsible collapsed={collapsed} width={250}>
        <div><img width="250" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>
          <Menu
            defaultOpenKeys={["applications", "projects"]}
            defaultSelectedKeys={params["page"] + "?status=" + urlParams['status']}
            theme="dark"
            mode="inline"
            onClick={(e) => menuClicked(e)}
            items={[
              {
                key: 'freelancers?status=selected',
                icon: <UserOutlined />,
                label: 'Find/Contact Freelancer(s)',
              },
              {
                icon: <ContainerOutlined />,
                key: "applications",
                label: 'Applications',
                children: [
                  {
                    key: 'applications?status=submitted',
                    icon: <ContainerOutlined />,
                    label: 'Submitted Applications',
                  },
                  {
                    key: 'applications?status=in-testing',
                    icon: <HighlightOutlined />,
                    label: 'In Testing Freelancers',
                  },
                  {
                    key: 'applications?status=rejected',
                    icon: <StopOutlined />,
                    label: 'Rejected Freelancers',
                  },
                  {
                    key: 'applications?status=inactive',
                    icon: <StopOutlined />,
                    label: 'Inactive Freelancers',
                  }
                ]
              },
              // {
              //   key: 'projects?status=new-project',
              //   icon: <UploadOutlined />,
              //   label: 'New Project - Budget',
              // },
              // {
              //             key: 'work-orders',
              //             icon: <ProfileOutlined />,
              //             label: (<a href={window.location.origin + "/freelancer/work-orders?fuzzyId=" + urlParams.fuzzyId} target="_parent">Work Orders</a>)
              //         },
              // {
              //   icon: <UploadOutlined />,
              //   label: 'Projects',
              //   key: "projects",
              //   children: [
              //     {
              //       key: 'projects?status=projects-all',
              //       icon: <UploadOutlined />,
              //       label: 'All',
              //     },
              //     {
              //       key: 'projects?status=Assigned',
              //       icon: <UploadOutlined />,
              //       label: 'Active',
              //     },
              //     {
              //       key: 'projects?status=Completed',
              //       icon: <ProfileOutlined />,
              //       label: 'Completed',
              //     },
              //     {
              //       key: 'projects?status=Evaluated',
              //       icon: <UploadOutlined />,
              //       label: 'Evaluated',
              //     }
              //   ]
              // },
              {
                key: 'document',
                icon: <InfoCircleOutlined />,
                label: (<a href={"https://docs.google.com/document/d/e/2PACX-1vTqHOZy81gk7Jo9vmXK77Hu1et6IVaqJmuH23qjcLdND9W1XIMe4Y9bzshUPNwcY5uc7ygvNrBbtEFF/pub"} target="_blank">Help</a>)
              },
              {
                key: 'freelancers?status=mapping',
                icon: <UploadOutlined />,
                label: 'Configuration',
              },
              {
                key: 'logout',
                icon: <LogoutOutlined />,
                label: (<a href={window.location.origin + "/logout"} target="_parent">Logout</a>)
              }
            ]}
          />
        </Sider>
        <Content
          style={{
            margin: '10px 16px',
            padding: 0,
            overflow: "scroll"
          }}>

          {(params["page"] == "projects" && urlParams.status == "Assigned") && (renderAssignedProjects())}
          {(params["page"] == "projects" && urlParams.status == "Completed") && (renderProjectsEvaluation())}
          {(params["page"] == "projects" && urlParams.status == "projects-all") && (renderAllProjects())}
          {(params["page"] == "freelancers" && urlParams.status == "selected") && (renderFreelancers())}
          {(params["page"] == "applications") && (renderApplications())}
          {(params["page"] == "freelancers" && urlParams.status == "in-testing") && (renderFreelancers())}
          {(params["page"] == "freelancers" && urlParams.status == "evaluation-form") && (renderEvaluationForm())}
          {(params["page"] == "projects" && urlParams.status == "Evaluated") && (renderEvaluatedProjects())}
          {(params["page"] == "projects" && urlParams.status == "new-project") && (renderNewProjects())}
        </Content>

        <Modal visible={projectStatusModal} title="Change Project Status" footer={null} onCancel={(e) => setProjectStatusModal(false)}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={changeProjectStatus}
            autoComplete="off">
            <Form.Item label="Status" name="status">
              <Select>
                <Select.Option value="Queried">QUERIED</Select.Option>
                <Select.Option value="Completed">COMPLETED</Select.Option>
                <Select.Option value="Assigned">ASSIGNED</Select.Option>
                <Select.Option value="Managed Internally">MANAGED INTERNALLY</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Actual Budget" name="budgetActual" extra="Fill if the project is moved to Completed state">
              <InputNumber min={0}/>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal visible={showFreelancerScore} width={1200} title="FreelancerScore" footer={null} onCancel={(e) => setShowFreelancerScore(false)}>
          <Table rowKey={record => record.id} columns={[
            {
              title: 'iTitle',
              dataIndex: 'ititle'
            },
            {
              title: "KPI",
              dataIndex: 'kpiName'
            },
            {
              width: 50,
              title: 'Score',
              dataIndex: 'user_rank'
            },
            {
              title: "Manager",
              dataIndex: 'managerName'
            },
            {
              title: "Created At",
              render: (record) => {
                return (
                  <span>{moment(record["created_at"]).format("lll")}</span>
                );
              }
            }
          ]} dataSource={freelancerScore} />
        </Modal>


        <Modal title="Recent Remarks" width={1000} visible={userNotesModalShow} onCancel={(e) => setUserNotesModalShow(false)} footer={[<Button key="back" onClick={(e) => setUserNotesModalShow(false)}>Close</Button>]}>
          <div dangerouslySetInnerHTML={{ __html: userNotesModalMessage }} />

          <Form style={{ marginTop: 20 }}
            name="basic"
            labelCol={{ span: 4 }} wrapperCol={{ span: 8 }}
            onFinish={saveUserNotes} autoComplete="off">

            <Form.Item label="Feedback" name="sentiment">
              <Select style={{ width: 120 }} >
                <Select.Option value="POSITIVE">POSITIVE</Select.Option>
                <Select.Option value="NEGATIVE">NEGATIVE</Select.Option>
                <Select.Option value="NEUTRAL">NEUTRAL</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="notes" label="Notes">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Can Freelancer see" name="access" >
              <Select style={{ width: 120 }} defaultValue="private">
                <Select.Option value="private">NO</Select.Option>
                <Select.Option value="public">YES</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>



        <Modal visible={applicationStatusModal} title="Change application status" footer={null} onCancel={(e) => setApplicationStatusModal(false)}>
          <Form name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }} onFinish={changeApplicationStatus} autoComplete="off">
            <Form.Item label="Status" name="status">
              <Select style={{ width: 120 }} >
                <Select.Option value="submitted">SUBMITTED</Select.Option>
                <Select.Option value="in-testing">TESTING</Select.Option>
                <Select.Option value="rejected">REJECTED</Select.Option>
                <Select.Option value="approved">APPROVED</Select.Option>
                <Select.Option value="inactive">INACTIVE</Select.Option>
              </Select>
            </Form.Item>

            {(urlParams.status == "in-testing") && (
              <Form.Item
                label="Score"
                name="score">
                <InputNumber min={1} max={100} />
              </Form.Item>
            )}


            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>

      </Layout>
      <Footer style={{ textAlign: 'center' }}><div className="myfooter"><a href="https://docs.google.com/document/d/e/2PACX-1vSssehMeX9FNAP534Q-ilH4XaI-wc__B5ASKIsGye90sV3YGatOhuP-KjDwPymJQ6o5Bpiu6VyNCsmY/pub">Terms and Conditions</a> <span style={{color:"#FFFFFF"}}>|</span> <a href="https://docs.google.com/document/d/e/2PACX-1vSR8nGaX8FiG9JNheeZG6-4INv9vPh1uStfYqEomhlliL1hCiRFgR2ZCFVVUGMebLmIn7dgi8z6GMob/pub">Privacy Policy</a> <span style={{color:"#FFFFFF"}}>|</span> <a href="https://newgen.co/aboutus">Newgen KnowledgeWorks (A Unit of Newgen DigitalWorks Pvt Ltd).

</a></div></Footer>
    </Layout>
  );



  function renderFreelancers() {

    const onFreelancerSearch = (values) => {
      console.log("OnSearch", values);
      var apiUrl = `${apiRoot}submissions/freelancer/api/find-for-invite?page=0`;

      if(values["dueDate"]!=undefined && values["startDate"]!=undefined)
      {
        if(moment(values["dueDate"]).valueOf() < moment(values["startDate"]).valueOf())
        {
          message.error("Due Date must be later than the Start Date");
          return;
        }
      }

      values["status"] = "APPROVED";
      values["nda"] = assignProjectProps["nda"];
      values["taxForm"] = assignProjectProps["tax_form"];
      if (values["nda"] == false) delete values["nda"];
      if (values["taxForm"] == false) delete values["taxForm"];
      if (values["skillId"] !== undefined) {
        values["skillId"] = (values["skillId"] + "").split(",");
        values["skillId"] = values["skillId"].filter((val) => val != "");
      }



      if (values["language"] !== undefined) {
        values["language"] = (values["language"] + "").split(",");
        values["language"] = values["language"].filter((val) => val != "");
      }

      if (values["languageCompetency"] !== undefined) {
        var selectedOne = values["languageCompetency"];
        values["languageCompetency"] = [];
        for(var i=languageCompetency.indexOf(selectedOne); i<languageCompetency.length; i++)
        {
          values["languageCompetency"].push(languageCompetency[i]);
        }
        values["languageCompetency"] = cleanArray(values["languageCompetency"]);
      }
      
      if (values["interest"] !== undefined) {
        values["interest"] = (values["interest"] + "").split(",");
        values["interest"] = values["interest"].filter((val) => val != "");
      }

      if (values["software"] !== undefined) {
        values["software"] = (values["software"] + "").split(",");
        values["software"] = values["software"].filter((val) => val != "");
      }

      if (values["subjectCompetency"] !== undefined) {
        var selectedOne = values["subjectCompetency"];
        values["subjectCompetency"] = [];
        for(var i=subjectCompetency.indexOf(selectedOne); i<subjectCompetency.length; i++)
        {
          values["subjectCompetency"].push(subjectCompetency[i]);
        }
        values["subjectCompetency"] = cleanArray(values["subjectCompetency"]);
      }

      if (values["ellCompetency"] !== undefined) {
        var selectedOne = values["ellCompetency"];
        values["ellCompetency"] = [];
        for(var i=ellCompetency.indexOf(selectedOne); i<ellCompetency.length; i++)
        {
          values["ellCompetency"].push(ellCompetency[i]);
        }
        values["ellCompetency"] = cleanArray(values["ellCompetency"]);
      }
      

      for (var prop in values) {
        if (values[prop] == undefined) delete values[prop];
        if (Array.isArray(values[prop]) && values[prop].length == 0) delete values[prop];
      }

      assignProjectProps = {...assignProjectProps, ...values};

      setFreelancers([]);
      axios.post(apiUrl, values, { headers }).then(res => {
        setFreelancers(massageFreelancerData(res));
      });

      if((values["projectId"]!=undefined) && (values["projectId"].length!=0))
      {
        axios.post(`${apiRoot}submissions/freelancer/api/freelance-project/filter/${values["projectId"]}`, values, { headers }).then(res => {
          console.log(res.data);
        });
        store(values["projectId"], values);  
      }
      setContactFeatureBox("block");
    }

    const assignUserToTeam = (e) => {
      console.log(currentObject);
      var payload = { "skillId": (assignProjectProps["skillId"] * 1), "userId": currentObject["id"] }
      payload["genre"] = "interest";
      console.log(payload);
      axios.post(`${apiRoot}submissions/freelancer/api/freelance-project-users/assign-skill`, payload, { headers }).then(res => {
        console.log(res.data);
        message.info("Freelancer has been associated to the team");
        setShowGroupMapping(false);
      });
    }

    const assignUserToDivision = (values) => {
      var payload = { "divisionId": (assignProjectProps["divisionId"] * 1), "userApplicationFuzzyId": currentObject["fuzzyId"] }
      axios.post(`${apiRoot}submissions/freelancer/api/divisions-users/assign`, payload, { headers }).then(res => {
        console.log(res.data);
        message.info("Freelancer is associated with Division");
        setShowDivisionMapping(false);
      });
    }

    const removeFreelancerFromDivision = (values) => {
      console.log(values);
      axios.delete(`${apiRoot}submissions/freelancer/api/divisions-users/remove/${values["fuzzy_id"]}`, { headers }).then(res => {
        console.log(res.data);
        message.info("User is removed from the division");
        setShowDivisionMapping(false);
      });
    }

    const removeFreelancerFromInterest = (values) => {
      console.log(values);
      axios.delete(`${apiRoot}submissions/freelancer/api/skills/remove/${values["fuzzy_id"]}`, { headers }).then(res => {
        console.log(res.data);
        message.info("User is removed from the team");
        setShowGroupMapping(false);
      });
    }



    const projectSelectionDone = (projectId) => {
      currentProjectId = projectId;
      changeKeyValue("freelanceProjectId", projectId);
      axios.get(`${apiRoot}submissions/freelancer/api/freelance-project/filter/${projectId}`, { headers }).then(res => {
        if (res.data["props"] != null) {
          var filterFormValues = JSON.parse(res.data["props"]);
          delete filterFormValues["startDate"];
          delete filterFormValues["dueDate"];
          if(filterFormValues["startDate"]!=undefined) filterFormValues["startDate"] = moment(filterFormValues["startDate"]);
          if(filterFormValues["dueDate"]!=undefined) filterFormValues["dueDate"] = moment(filterFormValues["dueDate"]);
          console.log(filterFormValues);
          searchForm.setFieldsValue(filterFormValues);
          setFilterData(filterFormValues);
        }
      });
    }

    const searchFreelanceEmailAndName = (emailLike) => {
      console.log(emailLike);
      setFreelancerEmailOptions([]);
      if (emailLike.length < 3) return;
      axios.get(`${apiRoot}submissions/freelancer/api/emailslike?q=` + emailLike, { headers }).then(res => {
        console.log(res.data);
        let tempOptions = [];
        tempOptions = res.data.map((d) => <Option key={d.name}>{d.name}</Option>);
        setFreelancerEmailOptions(tempOptions);
      });
    }

    const onFreelancerSearchFailed = (errorInfo) => {
      message.error(errorInfo.errorFields[0].errors[0]);
      setContactFeatureBox("none");
    }

    return (
      <div>
        <p>This system is tied to PubKit.  If you can't find your project in the Select the Project dropdown, kindly visit <a href={"https://pubkit.newgen.co/"}>PubKit</a> and/or <a href={window.location.origin + "/manager/projects?status=new-project"}>New Project - Budget</a> to set it up.</p>
        <div className="searchfilter">
          <div className="searchfilterheader">Find/Contact Freelancer(s)</div>
          <Form
            name="basic"
            labelCol={{ span: 11 }}
            wrapperCol={{ span: 8, }} initialValues={filterData} form={searchForm}
            onFinish={onFreelancerSearch} onFinishFailed={onFreelancerSearchFailed} autoComplete="off">
            <div style={{ display: 'inline-block', width: "100%" }}>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "33%" }}>
                  <Form.Item name="projectId" label="Select the Project" rules={[{ required: false, message:"Select the Project" }]}>
                    <Select allowClear
                      showSearch
                      value={currentProjectId}
                      style={{ width: "150%" }}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={searchFreelanceProjects}
                      notFoundContent={null}
                      onSelect={projectSelectionDone}>
                      {freelanceProjectOptions}
                    </Select>
                  </Form.Item></Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Service" name="skillId" rules={[{ required: true, message:"Select the service" }]} initialValue={["10"]}>
                    <Select defaultValue={["10"]} allowClear showSearch optionFilterProp="children" style={{ width: "150%" }}
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {skillList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col><Col style={{ width: "33%" }}>
                  <Form.Item label="Division" name="divisionId">
                    <Select allowClear showSearch optionFilterProp="children"
                      style={{ width: "150%" }}
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {divisionList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item></Col>
              </Row>
              <Row><Col style={{ width: "33%" }}>
                <Form.Item label="Start Date" name="startDate">
                  <DatePicker allowClear
                    style={{ width: "150%" }} />
                </Form.Item>
              </Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Estimated Due Date" name="dueDate">
                    <DatePicker allowClear
                      style={{ width: "150%" }} />
                  </Form.Item>
                </Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Mandatory Style Knowledge?" name="citation">
                    <Select mode="tags" showSearch optionFilterProp="children" allowClear style={{ width: "150%" }}
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {citationList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row><Row>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Copyedit/Proofread weight" name="editingStyle">
                    <Select allowClear style={{ width: "150%" }}>
                      <Option value="Light/1">Light/1</Option>
                      <Option value="Medium/2">Medium/2</Option>
                      <Option value="Heavy/3">Heavy/3</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Experience in Tool(s)" name="software">
                    <Select mode="tags" style={{ width: "150%" }} showSearch optionFilterProp="children" allowClear
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {softwareList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Country" name="country">
                    <Select mode="tags" allowClear showSearch optionFilterProp="children" style={{ width: "150%" }}
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {countryList.map((eachItem) => (<Option key={eachItem.name}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>

                </Col>
              </Row><Row>
                <Col style={{ width: "33%" }}>
                  <Form.Item name="email" label="Find particular freelancer: [enter email address or name]">
                    <Select showSearch allowClear
                      style={{ width: "150%" }}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={searchFreelanceEmailAndName}>
                      {freelancerEmailOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item label="Group" name="interest">
                    <Select showSearch optionFilterProp="children" allowClear style={{ width: "150%" }} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {interestList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item></Col>
                <Col style={{ width: "33%" }}>
                  <Form.Item name="nda" label="NDA Signed">
                    <Checkbox defaultChecked={false} onChange={(e) => onCheckboxChange("nda", e)} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: "33%" }}>
                </Col>
              </Row>

              <Row>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="Language" name="language">
                    <Select mode="tags" allowClear style={{ width: "150%" }} showSearch optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {languageList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="Language Proficiency" name="languageCompetency">
                    <Select allowClear>
                      {languageCompetency.map(each => 
                        <Option value={each}>{each}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="Education Subject Specialisms" name="subject">
                    <Select mode="tags" allowClear style={{ width: "150%" }} showSearch optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {subjectList.filter(eachItem => eachItem.forAll==true).map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="Subject Proficiency" name="subjectCompetency">
                    <Select allowClear>
                      {subjectCompetency.map(each => 
                        <Option value={each}>{each}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>


              <Row>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="ELL Subject Specialisms" name="ell">
                    <Select mode="tags" allowClear style={{ width: "150%" }} showSearch optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {ellList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="ELL Proficiency" name="ellCompetency">
                    <Select allowClear>
                      {ellCompetency.map(each => 
                        <Option value={each}>{each}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="Academic subject specialisms non-STEM" name="nonStem">
                    <Select mode="tags" allowClear style={{ width: "150%" }} showSearch optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {nonStemList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "50%" }}>
                  <Form.Item label="Academic subject specialisms STEM" name="stem">
                    <Select mode="tags" style={{ width: "150%" }} showSearch optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                      {stemList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <center>
                <Space>
                  <Form.Item >
                    <Button type="primary" htmlType="submit">SEARCH</Button>
                  </Form.Item>
                  &#8195;
                  <Form.Item >
                    <Button type="primary" onClick={(e) => window.location.href = window.location.href} >RESET</Button>
                  </Form.Item>
                </Space>
              </center>
            </div>
          </Form>
        </div>

        <p><small>Here is the approved list of freelancers based on your filter(s). Click the tickbox to the left of a freelancer's name to "select" them; you can select as many freelancers as you wish; after selecting,  you can contact them about your project through the system or simply copy all their email addresses by using the buttons at the bottom of the list.</small></p>
        <Table scroll={{ y: 500 }} rowKey={record => record.id} rowSelection={{
          selectedFreelancerIds,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedFreelancerIds(selectedRowKeys);
            console.log(selectedRowKeys);
          }
        }} pagination={{ pageSize: 50 }} columns={[
          {
            title: <TeamOutlined />,
            width: 40,
            render: (record) => {
              return (
                <span className='App-link'><a onClick={(e) => showDivisionDialog(record)}><LinkOutlined /></a></span>
              );
            }
          },
          {
            width: 40,
            key: "email",
            title: <MailOutlined />,
            render: (record) => {
              return (
                <span className='App-link'><a onClick={(e) => copyCurrentEmail(record)}><CopyOutlined /></a></span>
              )
            }
          },
          {
            key: "full_name",
            title: 'Name',
            render: (record) => {
              return (
                <span><span className='App-link'><a onClick={(e) => window.open(window.location.origin + "/user/application?fuzzyId=" + record.fuzzyId + "&page=application&user=manager", "hello", "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=" + (window.innerWidth * 0.75) + ",height=" + window.innerHeight + ",left=250,top=0")}>{record.full_name}</a></span> <br /> {record.skills}</span>
              );
            }
          },
          {
            width: 80,
            title: '(T)Score',
            dataIndex: 'score'
          },
          {
            width: 80,
            title: '(E)Score',
            render: (record) => {
              return (
                <span className='App-link'><a onClick={(e) => showUserScores(record)}>{record.averageScore}</a></span>
              );
            }
          },
          {
            title: 'NDA',
            width: 80,
            dataIndex: 'ndaAt'
          },
          {
            title: <CommentOutlined />,
            width: 50,
            render: (record) => {
              return (
                <span className='App-link'><a onClick={(e) => showUserNotes(record)}><CommentOutlined /></a></span>
              );
            }
          },
          {
            title: 'Recent Price',
            width: 120,
            render: (record) => {
              return (
                <span>{((record["price"]!==undefined) ? record["price"] + " " + record["currency"] + " " + record["unit"] : "")}</span>
              );
            }
          }
        ]} dataSource={freelancers} />

        <div style={{"display":contactFeatureBox, "width":500}}>
          <Space>
            <Button type="primary" onClick={(e) => window.location.href = window.location.href}>NEW SEARCH</Button>
            <Button type="primary" onClick={(e) => showAssignProjectModalVisibleIfSelected()}>CONTACT SELECTED FREELANCERS</Button>
            <Button type="primary" onClick={(e) => copySelectedEmails()}>COPY EMAILS</Button>
          </Space>
        </div>

        <Modal
          width={(window.innerWidth-100)}
          visible={assignProjectModalVisible}
          title="Send Invitations"
          onOk={(e) => sendFreelancerInvitation(e)}
          onCancel={assignProjectModalCancel}
          footer={[
            <Button key="back" onClick={assignProjectModalCancel}>RETURN</Button>,
            <Button key="submit" type="primary" onClick={(e) => sendFreelancerInvitation(e)}>SUBMIT</Button>
          ]}>
          <Form
            name="basic"
            labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}
            initialValues={emailTemplateBody}
            form={composeEmailForm}
            autoComplete="off">
            <Form.Item
              label="Select Project"
              name="freelanceProjectId"
              rules={[{ required: true }]}>
              <Select
                defaultValue={currentProjectId}
                showSearch
                allowClear
                style={{ width: 500 }}
                showArrow={false}
                filterOption={false}
                onSearch={searchFreelanceProjects}
                onChange={(e) => changeKeyValue("freelanceProjectId", e)}>
                {freelanceProjectOptions}
              </Select>
            </Form.Item>


            <Form.Item
              label="Select Template" name="emailTemplateId" rules={[{ required: true }]}>
              <Select style={{ width: 500 }}
                showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} onChange={(e) => onEmailTemplateSelect("emailTemplateId", e)}>
                {emailTemplateOptions}
              </Select>
            </Form.Item>

            <Form.Item label="Subject" name="emailSubject" rules={[{ required: true }]}>
              <Input defaultValue={assignProjectProps["emailSubject"]} onChange={(e) => changeKeyValue("emailSubject", e.target.value)}/>
            </Form.Item>
          </Form>

          <CKEditor
            editor={ClassicEditor}
            data={emailTemplateBody["body"]}
            onReady={editor => {
              console.log('Editor is ready to use!', editor);
              editor.editing.view.change(writer => {
                writer.setStyle("height", (window.innerHeight-400) + "px", editor.editing.view.document.getRoot());
              });
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              changeKeyValue("body", data);
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
        </Modal>


        <Modal
          width={500}
          visible={showGroupMapping}
          title="Group Mapping"
          onOk={(e) => assignUserToTeam(e)}
          onCancel={(e) => setShowGroupMapping(false)}>
          <Form
            name="basic"
            labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off">
            <Form.Item
              label="Group"
              name="skillId"
              rules={[{ required: true }]}>
              <Select
                showSearch
                allowClear
                showArrow={false}
                filterOption={false}
                onChange={(e) => changeKeyValue("skillId", e)}>
                {interestList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
              </Select>
            </Form.Item>
          </Form>
          <Table dataSource={freelancerSkill} columns={[
            { "title": "Name", "dataIndex": "skillName" },
            {
              "title": "Delete", render: (record) => {
                return (
                  <span className='App-link'><a onClick={(e) => removeFreelancerFromInterest(record)}><DeleteOutlined /></a></span>
                )
              }
            },
            {
              title: 'Created At',
              dataIndex: "created_at",
              render: (value) => (
                <span>{moment(value).format("lll")}</span>
              )
            }
          ]
          } />

        </Modal>

        <Modal
          width={500}
          visible={showDivisionMapping}
          title="Division Mapping"
          onOk={(e) => assignUserToDivision(e)}
          onCancel={(e) => setShowDivisionMapping(false)}>
          <Form
            name="basic"
            labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off">
            <Form.Item
              label="Division"
              name="divisionId"
              rules={[{ required: true }]}>
              <Select
                showSearch
                allowClear
                showArrow={false}
                filterOption={false}
                onChange={(e) => changeKeyValue("divisionId", e)}>
                {divisionList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
              </Select>
            </Form.Item>
          </Form>
          <Table dataSource={freelancerDivision} columns={[
            { "title": "Name", "dataIndex": "divisionName" },
            {
              "title": "Delete", render: (record) => {
                return (
                  <span className='App-link'><a onClick={(e) => removeFreelancerFromDivision(record)}><DeleteOutlined /></a></span>
                )
              }
            },
            {
              title: 'Created At',
              dataIndex: "created_at",
              render: (value) => (
                <span>{moment(value).format("lll")}</span>
              )
            }
          ]
          } />
        </Modal>


      </div>
    )
  }

  function renderApplications() {
    if (urlParams.status == "in-testing") pageTitle = "In Testing Applications";
    if (urlParams.status == "rejected") pageTitle = "Rejected Freelancer's Service Applications";
    if (urlParams.status == "inactive") pageTitle = "Inactive Applications";

    const columns = [
      {
        key: "email",
        width: 30,
        title: 'Email',
        render: (record) => {
              return (
                <span className='App-link'><a onClick={(e) => copyCurrentEmail(record)}><CopyOutlined /></a></span>
              )
            }
      },
      {
        title: 'Name',
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => window.open(window.location.origin + "/user/application?fuzzyId=" + record.userApplicationFuzzyId + "&page=application", "hello", "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=" + window.innerWidth + ",height=" + window.innerHeight + ",left=0,top=0")}>{record.fullName}</a></span>
          );
        }
      },
      {
        key: "status",
        title: 'Status',
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => changeCurrentObject(record)}>{record.status}</a></span>
          );
        }
      },
      {
        title: 'Notes',
        width: 30,
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => showUserNotes(record)}><CommentOutlined /></a></span>
          );
        }
      },
      {
        dataIndex: "skillName",
        title: 'Service'
      },
      {
        title: 'Created At',
        dataIndex: "createdAt",
        width:120,
        render: (value) => (
          <span>{moment(value).format("YYYY-MM-DD")}</span>
        )
      },
      {
        title: 'Group',
        dataIndex: "interest"
      },
      {
        title: 'Country',
        dataIndex: "country",
      },
      {
        key:"manager",
        title: 'Manager',
        dataIndex: "managerName"
      }
    ];

    var revisedColumn = columns;
    if(urlParams.status == "submitted")
    {
      revisedColumn = columns.slice(0, -1);
    }

    return (
      <div>
        <Title level={5}>{pageTitle}</Title>
        <Table rowKey="uniqueId" columns={revisedColumn} dataSource={freelancerApplications} />

        <Modal
          visible={applicationStatusModal}
          title="Title"
          footer={null}
          onCancel={(e) => setApplicationStatusModal(false)}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={changeApplicationStatus}
            autoComplete="off"
          >
            <Form.Item
              label="Status"
              name="status">
              <Select style={{ width: 120 }} >
                <Select.Option value="submitted">SUBMITTED</Select.Option>
                <Select.Option value="in-testing">TESTING</Select.Option>
                <Select.Option value="rejected">REJECTED</Select.Option>
                <Select.Option value="approved">APPROVED</Select.Option>
                <Select.Option value="inactive">INACTIVE</Select.Option>
                <Select.Option value="active">ACTIVE</Select.Option>
              </Select>
            </Form.Item>

            {(urlParams.status == "in-testing") && (
              <Form.Item
                label="Score"
                name="score"
              >
                <InputNumber min={1} max={100} />
              </Form.Item>
            )}


            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>

      </div>
    )
  }


  function renderAssignedProjects() {

    const fetchProjectFreelancers = (record) => {
      console.log(record);
      axios.get(`${apiRoot}submissions/freelancer/api/freelance-project/${record.fuzzyId}/freelancers`, { headers }).then(res => {
        console.log(res.data)
        setProjectFreelancers(res.data);
        setProjectFreelancersModal(true);
        setCorrespondenceBody("");
      });
    }

    const resendInvitationEmail = () => {
      console.log(currentObject);
      axios.post(`${apiRoot}submissions/freelancer/api/freelance-project-users/${currentObject["fuzzyId"]}/resend-invitation`, currentObject, { headers }).then(res => {
        console.log(res.data);
        message.info(res.data.message);
      });
    }


    const pullEmailByManuscriptNumber = (e, record) => {
      console.log(record);
      setCurrentObject(record);
      axios.get(`${apiRoot}submissions/freelancer/api/freelance-project-users/${record.fuzzyId}/find-email`, { headers }).then(res => {
        console.log(res.data)
        if (res.data["email"] == null) return;
        setCorrespondenceBody("<div>" + res.data["email"]["content"] + "</div><div style='margin-bottom:10px'>Sent At: " + moment(res.data["email"]["createdAt"]).format("lll") + "</div>");
      });
    }

    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: "Service",
        dataIndex: 'taskName'
      },
      {
        title: 'iTitle',
        dataIndex: 'iTitle'
      },
      {
        title: 'Budget',
        render: (record) => {
          return (
            <span>{record.budgetMin + " " + record.currency}</span>
          )
        }
      },
      {
        title: 'Status',
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => freelanceProjectManagerDecisions(record, e)}><FormOutlined /> {record.status}</a></span>
          )
        }
      },
      {
        key: "createdAt",
        title: 'Created At',
        render: (record) => {
          return (
            <span>{moment(record["createdAt"]).format("lll")}</span>
          )
        }
      },
      {
        key: "view",
        title: 'Show',
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => fetchProjectFreelancers(record)}>{"Response: " + record.responseCount}</a></span>
          );
        }
      }
    ];


    return (
      <div>
        <Title level={5}>Active Projects</Title>
        <Table rowKey="fuzzyId" columns={columns} dataSource={freelancers} />
        <Modal width={1000} visible={projectFreelancersModal} title="Invitation sent to"
          onOk={(e) => setProjectFreelancersModal(false)} onCancel={(e) => setProjectFreelancersModal(false)}
          footer={[
            <Button key="back" onClick={(e) => setProjectFreelancersModal(false)}>Close</Button>
          ]}>

          <Table rowKey="fuzzyId" pagination={false} columns={[{
            title: 'Name',
            dataIndex: 'fullName'
          },
          {
            title: 'Status',
            dataIndex: 'status'
          },
          {
            title: 'Freelancer Decision',
            dataIndex: 'freelancerDecision'
          },
          {
            title: 'Email',
            render: (record) => {
              return (
                <span className='App-link'><a onClick={(e) => pullEmailByManuscriptNumber(e, record)}><MailOutlined /></a></span>
              )
            }
          },
          {
            title: 'Manager Decision',
            render: (record) => {
              return (
                <Select defaultValue={record.managerDecision} onChange={(e) => managerSelectFreelancerForProject(e, record)} style={{ width: 120 }} >
                  <Select.Option value="SELECTED">SELECTED</Select.Option>
                  <Select.Option value="REJECTED">REJECTED</Select.Option>
                  <Select.Option value="WAITING-LIST">WAITING LIST</Select.Option>
                </Select>
              )
            }
          },
          {
            key: "createdAt",
            title: 'Created At',
            render: (record) => {
              return (
                <span>{moment(record["createdAt"]).format("lll")}</span>
              )
            }
          }]} dataSource={projectFreelancers} />

          <div style={{ border: "1px solid gray", marginTop: 10, padding: "10px" }}>
            <div dangerouslySetInnerHTML={{ __html: correspondenceBody }} />
            <Button onClick={(e) => resendInvitationEmail()} target="_blank">Resend</Button>
          </div>

        </Modal>

      </div>
    )

  }



  function renderAllProjects() {

    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: "Service",
        dataIndex: 'taskName'
      },
      {
        title: 'Due',
        width:180,
        render: (record) => (
          <span>{moment(record.dueDate).format("lll")}</span>
        )
      },
      {
        title: 'Response Due',
        width:180,
        render: (record) => (
          <span>{moment(record.responseDueDate).format("lll")}</span>
        )
      },
      {
        title: 'iTitle',
        dataIndex: 'iTitle'
      },
      {
        title: 'Status',
        width:250,
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => freelanceProjectManagerDecisions(record, e)}>{record.status}</a></span>
          )
        }
      }
    ];


    return (
      <div>
        <Title level={5}>All Projects including WIP, Completed</Title>
        <Table rowKey="fuzzyId" columns={columns} dataSource={listProjects} />
      </div>
    )

  }




  function renderEvaluatedProjects() {

    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: "Service",
        dataIndex: 'taskName'
      },
      {
        title: 'Due',
        render: (record) => (
          <span>{moment(record.dueDate).format("lll")}</span>
        )
      },
      {
        title: 'Status',
        render: (record) => {
          return (
            <Button icon={<FormOutlined />} onClick={(e) => freelanceProjectManagerDecisions(record, e)}>{record.status}</Button>
          )
        }
      },
      {
        title: 'iTitle',
        dataIndex: 'iTitle'
      }
    ];

    return (
      <div>
        <Title level={5}>Evaluated Projects</Title>
        <Table rowKey="id" columns={columns} dataSource={freelancers} />
      </div>
    )
  }





  function renderProjectsEvaluation() {

    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: "Service",
        dataIndex: 'taskName'
      },
      {
        title: 'Due',
        render: (record) => (
          <span>{moment(record.dueDate).format("lll")}</span>
        )
      },
      {
        title: 'iTitle',
        dataIndex: 'iTitle'
      },
      {
        title: 'Status',
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => freelanceProjectManagerDecisions(record, e)}><FormOutlined />{record.type}</a></span>
          )
        }
      },
      {
        title: "Link",
        render: (record) => {
          return (
            <span className='App-link'><a type="link" onClick={(e) => window.location.href = `/manager/freelancers?status=evaluation-form&fuzzyId=${record.freelanceProjectUserfuzzyId}`} target="_blank">Evaluation Form</a></span>
          );
        }
      }
    ];


    return (
      <div>
        <Title level={5}>Please submit freelancers KPI on these projects</Title>
        <Table rowKey="iTitle" columns={columns} dataSource={freelancers} />
      </div>
    )
  }


  function renderEvaluatedProjects() {
    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: "Service",
        dataIndex: 'taskName'
      },
      {
        title: 'Due',
        render: (record) => (
          <span>{moment(record.dueDate).format("lll")}</span>
        )
      },
      {
        key: "type",
        title: 'Type',
        render: (record) => {
          return (
            <span className='App-link'><a onClick={(e) => freelanceProjectManagerDecisions(record, e)}><FormOutlined />{record.type}</a></span>
          )
        }
      },
      {
        title: 'iTitle',
        dataIndex: 'iTitle'
      },
      {
        title: 'Manager',
        dataIndex: 'managerName'
      },

    ];

    return (
      <div>
        <Title level={5}>Completed Projects</Title>
        <Table rowKey="id" columns={columns} dataSource={freelancers} />
      </div>
    )
  }





  function renderEvaluationForm() {

    const remarksEntered = (e) => {
      textAreaText = e.target.value;
    };

    const submitEvaluationForm = (values) => {
      var kpis = [];
      for (var prop in values) {
        var kpiId = prop.replace("userRank_", "");
        if (kpiId == prop) continue;
        if (values[prop] === undefined) { values[prop] = 0; }
        kpiId = (kpiId * 1);
        var userScore = (values[prop] * 1);
        kpis.push({ "id": kpiId, "score": userScore });
      }
      values["kpis"] = kpis;
      values["remarks"] = textAreaText;

      axios.post(`${apiRoot}submissions/freelancer/api/freelance-project-users/${urlParams["fuzzyId"]}/save/kpis`, values, { headers }).then(res => {
        console.log(res.data);
        message.info(res.data.message, 10);
        setTimeout(function () { window.location.href = "/manager/projects?status=Completed" }, 5000);
      });

    }

    return (
      <div style={{ width: 800 }}>

        <div style={{ border: "1px solid gray", marginBottom: "20px", padding: "10px" }}>
          <p>Project Name: {currentProject.name}</p>
          <p>Task Name: {currentTask.name}</p>
          <p>Freelancer: {currentFreelancer.fullName}</p>
        </div>

        <Form
          name="basic"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={submitEvaluationForm}
          autoComplete="off">

          {kpis.map((eachKpi) =>
            <Form.Item name={"userRank_" + eachKpi.id} label={eachKpi.description} extra="(1 is poor; 5 is excellent)">
              <Select style={{ width: 170 }}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
              </Select>
            </Form.Item>
          )}


          <Form.Item name="remarks" label="Additional Remarks" extra="Please consider adding notes about things the freelancer can work on.">
            <TextArea onChange={remarksEntered} rows={4} />
          </Form.Item>


          <Form.Item label="Can Freelancer see?" name="access">
            <Select style={{ width: 120 }} defaultValue="private">
              <Select.Option value="private">NO</Select.Option>
              <Select.Option value="public">YES</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 12, span: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button> &#8195;
            <Button type="primary" onClick={(e) => window.history.back()}>
              Back
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }






  function renderNewProjects() {

    const addFreelanceProject = (assignProjectProps) => {
      if(isNaN(assignProjectProps["projectId"])) assignProjectProps["projectId"] = currentObject["projectId"];
      if(isNaN(assignProjectProps["taskId"])) assignProjectProps["taskId"] = currentObject["taskId"];
      if(isNaN(assignProjectProps["skillId"])) assignProjectProps["skillId"] = currentObject["skillId"];

      assignProjectProps["projectId"] = (assignProjectProps["projectId"] * 1);
      assignProjectProps["taskId"] = (assignProjectProps["taskId"] * 1);
      console.log(assignProjectProps);
      if(moment(assignProjectProps["dueDate"]).valueOf() < moment(assignProjectProps["responseDueDate"]).valueOf())
      {
        message.error("Due Date must be later than the Response Due Date");
        return;
      }

      assignProjectProps["dueDate"] = moment(assignProjectProps["dueDate"]).format("yyyy-MM-DD");
      assignProjectProps["responseDueDate"] = moment(assignProjectProps["responseDueDate"]).format("yyyy-MM-DD");
      assignProjectProps["fuzzyId"] = currentObject["fuzzyId"];

      axios.post(`${apiRoot}submissions/freelancer/api/project/add`, assignProjectProps, { headers }).then(res => {
        console.log(res.data);
        message.info(res.data.message, 10);
        setTimeout(function () { window.location.href = window.location.href; }, 5000);
      });
    }


    const findProjectTasks = (projectId) => {
      changeKeyValue("projectId", projectId);
      axios.get(`${apiRoot}submissions/freelancer/api/project/${projectId}/tasks`, { headers }).then(res => {
        var projectTaskList = res.data.filter(record => record["task_type"] != "CheckpointTask");
        projectTaskList = projectTaskList.filter(record => record["task_type"] != "BufferTask");
        console.log(projectTaskList)
        setProjectTasks(projectTaskList);
        var tempOptions = [];
        for (var i = 0; i < projectTaskList.length; i++) {
          tempOptions.push(<Option key={projectTaskList[i]["id"]}>{projectTaskList[i]["name"]}</Option>);
        }
        setProjectTaskOptions(tempOptions);
      });
    }


    const projectFileUpload = (record) => {
      console.log(record)
      axios.get(`${apiRoot}submissions/freelancer/api/files/list/` + record.projectId + "/task/" + record.taskId, { headers }).then(res => {
        console.log(res.data)
        setProjectFiles(res.data);
      });
      setCurrentObject(record);
      setShowProjectFileUpload("block");
    }

    const editNewFreelanceProject = (record) => {
      console.log(record);
      setCurrentObject(record);
      var formValues = {...record};
      formValues["responseDueDate"] = moment(formValues["responseDueDate"]);
      formValues["dueDate"] = moment(formValues["dueDate"]);
      formValues["projectId"] = formValues["name"];
      formValues["taskId"] = formValues["taskName"];
      var selectedSkill = skillList.filter(item => item.id=formValues["skillId"]);
      if(selectedSkill.length>0)
      {
        formValues["skillId"] = selectedSkill[0]["name"];
      }
      newProjectForm.setFieldsValue(formValues);
      setShowProjectFileUpload("block");
    }

    

    return (
      <div>

        <Content
          className="site-layout-background">
          <div className="searchfilter">
            <div className="searchfilterheader">New Project - Budget</div>
            <Card>
              <p>This system is tied to PubKit. Non-PubKit users can still use it, but do need to ensure that there is a minimal PubKit record. To find the PubKit project(s) you wish to freelance, just  type Author Name or Title of the Book or DOI or ISBN into the Project Field; if it exists, you can select it from a drop down. If you can't find the project, you can create a new project by visiting <a href={apiRoot + "titles/ready_to_start"}>PubKit</a></p>
              <Form
                form={newProjectForm}
                style={{ marginTop: "30px" }} labelCol={{ span: 11 }} wrapperCol={{ span: 8 }} name="register" onFinish={addFreelanceProject}>
                <Row style={{ width: "100%" }}><Col style={{ width: "33%" }}>
                  <Form.Item
                    name="projectId"
                    label="Project" rules={[{ required: true, "message":"Please select a project" }]}>
                    <Select
                      showSearch
                      value={currentProjectId}
                      placeholder={props.placeholder}
                      style={{ width: "150%" }}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={searchProjects}
                      onChange={(e) => findProjectTasks(e)}
                      notFoundContent={null}>
                      {projectOptions}
                    </Select>
                  </Form.Item>
                </Col>

                  <Col style={{ width: "33%" }}>
                    <Form.Item
                      name="taskId"
                      label="Task" rules={[{ required: true, "message":"Please select a Task" }]}>
                      <Select
                        showSearch
                        value={projectTasks}
                        style={{ width: "150%" }}
                        showArrow={false}
                        filterOption={false}
                        onChange={(e) => changeKeyValue("taskId", e)}>
                        {projectTaskOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "33%" }}>
                    <Form.Item
                      rules={[{ required: true, "message":"Please provide the Due Date" }]}
                      name="dueDate"
                      label="Due Date">
                      <DatePicker style={{ width: "150%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ width: "33%" }}>
                  <Form.Item
                      name="unit"
                      label="Unit for Project Pay">
                      <Select
                        showSearch
                        style={{ width: "150%" }}
                        showArrow={false}
                        filterOption={false}
                        onChange={(e) => changeKeyValue("unit", e)}>
                        <Option value="PAGE">Per Page</Option>
                        <Option value="HOUR">Per Hour</Option>
                        <Option value="FIGURE">Per Figure</Option>
                        <Option value="TOTAL">Total (Unit Qty=1)</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "33%" }}>
                  <Form.Item
                      name="budgetMin"
                      label="Unit Budget">
                      <InputNumber min={0} style={{ width: "150%" }} />
                    </Form.Item>
                  </Col><Col style={{ width: "33%" }}>
                  <Form.Item rules={[{ required: true, "message":"Please provide the Response Due Date" }]}
                      name="responseDueDate"
                      label="Response Due Date">
                      <DatePicker style={{ width: "150%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ width: "33%" }}>
                  <Form.Item
                      name="currency"
                      label="Unit Currency">
                      <Select
                        showSearch
                        style={{ width: "150%" }}
                        showArrow={false}
                        filterOption={false}
                        onChange={(e) => changeKeyValue("currency", e)}>
                        <Option key="USD">USD</Option>
                        <Option key="GBP">GBP</Option>
                        <Option key="INR">INR</Option>
                        <Option value="SAR">SAR</Option>
                      </Select>
                    </Form.Item>
                  </Col><Col style={{ width: "33%" }}>
                    <Form.Item
                      name="quantity"
                      label="Unit Quantity">
                      <InputNumber min={0} />
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "33%" }}>
                    <Form.Item
                      name="skillId"
                      label="Service" rules={[{ required: true, message:"Select the Service" }]}>
                      <Select allowClear showSearch optionFilterProp="children" style={{ width: "150%" }}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {skillList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <center>
                  <Space>
                    <Form.Item >
                      <Button type="primary" htmlType="submit">SUBMIT</Button> &#x02003;
                    </Form.Item>
                  </Space></center>
              </Form>
            </Card>
          </div>
          <Title className='dialogHeader' style={{ marginTop: 30 }} level={5}>New Projects</Title>
          <Table rowKey={record => record.fuzzyId} columns={[
            {
              key: "name",
              title: 'Name',
              dataIndex: 'name'
            },
            {
              title: "Service",
              dataIndex: 'taskName'
            },
            {
              title: 'iTitle',
              dataIndex: 'iTitle'
            },
            {
              title: 'Due',
              render: (record) => (
                <span>{moment(record.dueDate).format("lll")}</span>
              )
            },
            {
              title: 'Budget',
              dataIndex: 'budgetMin'
            },
            {
              title: 'Currency',
              dataIndex: 'currency'
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity'
            },
            {
              title: 'Unit',
              dataIndex: 'unit'
            },
            {
              title: 'Files',
              render: (value) => (
                <span className='App-link'><a onClick={(e) => projectFileUpload(value)}><CloudUploadOutlined /></a></span>
              )
            },
            {
              title: 'Edit',
              render: (value) => (
                <span className='App-link'><a onClick={(e) => editNewFreelanceProject(value)}><EditOutlined /></a></span>
              )
            }
          ]} dataSource={freelanceProjects} />


          <div style={{ "display": showProjectFileUpload }}>
            <Card title="Project Files" bordered={false} style={{ width: 800 }}>
              <input type="file" onChange={handleFileInput} />
              <Button onClick={() => uploadFile(selectedFile)} type="primary">Upload</Button>

              <Table pagination={false} style={{ marginTop: 20 }} rowKey={record => record.id} columns={[
                {
                  title: 'Name',
                  dataIndex: 'filename'
                },
                {
                  title: 'Created At',
                  render: (record) => (
                    <span>{moment(record.createdAt).format("lll")}</span>
                  )
                },
                {
                  title: 'Download',
                  render: (value) => (
                    <span className='App-link'><a onClick={(e) => downloadS3File(value)}><CloudDownloadOutlined /></a></span>
                  )
                }
              ]} dataSource={projectFiles} />
            </Card>
          </div>
        </Content>
      </div>
    )
  }
};