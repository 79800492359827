import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import './antd.css';
import axios from 'axios';
import store from 'store2';
import { apiRoot } from "./Config";
import * as qs from 'query-string';

import { Button, Card, Form, Layout } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
const { Header, Footer, Content } = Layout;

function UserInfo() {

    const params = useParams();
    const urlParams = qs.parse(window.location.search);

    useEffect(() => {
        console.log(store.get("freelancer"));
        console.log(params);
        console.log(urlParams);

        if (params.fuzzyid !== undefined) {
            axios.post(`${apiRoot}submissions/freelancer/public/verify-email/` + params.fuzzyid, {})
                .then(res => {
                    if (res.data.status != 200) {
                        window.location.href = "/login/login";
                        return;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        }

    }, []);


    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Layout className="site-layout">
                <div><img width="250" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>
                <Content>
                    <Card style={{
                        width: 800,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "50px"
                    }}>
                        {(urlParams.message == "user-registration") && (userRegistration())}
                    </Card>
                </Content>
            </Layout>
            <Footer style={{ textAlign: 'center' }}><small>Newgen KnowledgeWorks (A Unit of Newgen DigitalWorks Pvt Ltd).

</small></Footer>
        </Layout>
    );
}

function userRegistration() {
    return (
        <center>
            <p>We sent you an email for verification. Please check your email and follow the details.</p>
            <p>Incase if can't see our email in yoru inbox, it is also possible that the email could have reached into your spam folder. Kindly check the spam folder too.</p>
            <p>Please <a href={window.location.origin}>click here</a> to go Login</p>
        </center>
    )
}

export default UserInfo;