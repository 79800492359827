import logo from './logo.svg';
import './App.css';
import Login from "./Login";
import Logout from "./Logout";
import Register from "./Register";
import Reset from "./Reset";
import Forgot from "./Forgot";
import Freelancer from "./Freelancer";
import Insurance from "./Insurance";
import DomainMails from "./DomainMails";
import Manager from "./Manager";
import './antd.css'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Application from './Application';
import Form from './Form';
import Autologin from './Autologin';
import Links from './Links';
import UserInfo from './UserInfo';


function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login/:message" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email/:fuzzyid" element={<Register />} />
          <Route path="/freelancer" element={<Freelancer />} />
          <Route path="/freelancer/applications/status" element={<Application />} />
          <Route path="/freelancer/application" element={<Application />} />
          <Route path="/freelancer/applications/status/:status" element={<Application />} />
          <Route path="/freelancer/:page" element={<Freelancer />} />
          <Route path="/user/application" element={<Application/>} />
          <Route path="/manager" element={<Manager/>} />
          <Route path="/manager/:page" element={<Manager/>}/>
          <Route path="/admin" element={<Manager />} />
          <Route path="/hr/insurance/:page/:fuzzyid" element={<Insurance />} />
          <Route path="/hr/emails/:page/" element={<DomainMails />} />
          <Route path="/hr/emails/:page/:unithead" element={<DomainMails />} />
          <Route path="/autologin/miscellaneous" element={<Autologin />} />
          <Route path="/autologin" element={<Autologin />} />
          <Route path="/links" element={<Links />} />
          <Route path="/userinfo" element={<UserInfo />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
