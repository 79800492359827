import React, { useState, useEffect } from 'react';
import backgroundImage from "./img/background.jpeg";

import './App.css';
import './antd.css';
import axios from 'axios';
import store from 'store2';
import { apiRoot } from "./Config";
import { Button, Card, Form, Input, Typography, Layout, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
import * as qs from 'query-string';
const { Header, Footer, Content } = Layout;


var urlParams = qs.parse(window.location.search);


function Reset() {

  const params = useParams();

  useEffect(() => {
    console.log(params);
  }, []);

  const onFinish = (values) => {    
    axios.get(`${apiRoot}submissions/public/password-reset/save-reset-password?token=${urlParams["token"]}&newPassword=${encodeURIComponent(values["newPassword"])}&confirmNewPassword=${encodeURIComponent(values["confirmNewPassword"])}`)
      .then(res => {
        console.log(res);
        if (res.data.status == 200) {
          message.info("This page will be redirected to the login page");
          setTimeout(function () { window.location.href = window.location.origin + "?message=reset-password-done"; }, 3000);
          return;
        }
        message.info(res.data["message"]);
      }).catch(function (error) {
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <div className="myfullscreen">
      <Card style={{
      }} id="loginform"
      >
        <div><img width="40%" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>

        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ background: "white" }}>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!',
                },
              ]}
            >
              <Input type="password"/>
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!',
                },
              ]}
            >
              <Input type="password"/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                RESET
              </Button>
            </Form.Item>
        </Form>
        <div id="alternativeLogin">
          Your password will reset
        </div>
      </Card>
      <div className="myfooter">Newgen KnowledgeWorks (A Unit of Newgen DigitalWorks Pvt Ltd).

</div>
    </div>
  );
}

export default Reset;