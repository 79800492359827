import logo from './logo.svg';
import './App.css';
import './antd.css';

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Radio
} from 'antd';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoot } from "./Config";
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'

const { Header, Sider, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


export default function Application(props) {
    const [form] = Form.useForm();
    const [applicationData, setApplicationData] = useState({});
    const params = useParams();

    var freelanceAuth = store("pubkit_auth");
    var headers = { ...freelanceAuth };

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        axios.post(apiRoot + ``, values, { headers })
            .then(res => {
                console.log(res);
            }).catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        console.log(params);
        var freelanceAuth = store("pubkit_auth");
        var headers = { ...freelanceAuth };

        var apiUrl = `${apiRoot}submissions/freelancer/api/user/find-application`;
        console.log(headers);
        axios.get(apiUrl, { headers }).then(res => {
            console.log(res.data)
            var applicationData = JSON.parse(res.data.payload);
            console.log(applicationData);
            //setApplicationData(applicationData);
            form.setFieldsValue(applicationData);
        });

    }, []);


    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const suffixSelector = (
        <Form.Item name="suffix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="USD">$</Option>
                <Option value="CNY">¥</Option>
            </Select>
        </Form.Item>
    );
    const [autoCompleteResult, setAutoCompleteResult] = useState([]);




    return (
        <Layout>
            
            <Layout className="site-layout">
                <Sider trigger={null} collapsible>
                <div><img width="250" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: '1',
                                icon: <UserOutlined />,
                                label: 'nav 1',
                            },
                            {
                                key: '2',
                                icon: <VideoCameraOutlined />,
                                label: 'nav 2',
                            },
                            {
                                key: '3',
                                icon: <UploadOutlined />,
                                label: 'nav 3',
                            },
                        ]}
                    />
                </Sider>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '10px 16px',
                        padding: 0,
                        minHeight: 280,
                    }}
                >
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        initialValues={applicationData}
                        scrollToFirstError
                    >
                        <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}

                        >
                            <TextArea rows={2} />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="Phone Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your phone number!',
                                },
                            ]}
                        >
                            <Input
                                addonBefore={prefixSelector}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="website"
                            label="Website"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input website!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="company"
                            label="Is your company"

                        >
                            <Radio.Group>
                                <Radio value="onePerson"> one person </Radio>
                                <Radio value="team"> a team </Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="vat"
                            label="VAT number(if applicable)"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="membership"
                            label="Professional body membership (if applicable)"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="working"
                            label="Are you interested in working for">
                            <Select>
                                <Option value="male">Trade</Option>
                                <Option value="female">Academic</Option>
                                <Option value="other">Education K-12</Option>
                                <Option value="other">Education post-secondary</Option>
                                <Option value="other">ELL K-12</Option>
                                <Option value="other">ELL post-secondary</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="newEmployee"
                            label="Were you referred by a Newgen employee? If Yes, please provide name here"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="servicesOffered"
                            label="Services offered"
                        >

                            <Select
                                showSearch
                                value={null}
                                placeholder={props.placeholder}
                                style={{ width: 150 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={null}
                                // onChange={(e) => findProjectTasks(e)}
                                notFoundContent={null}
                            >
                                { }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="subSpecialism"
                            label="Subject specialisms"
                        >

                            <Select
                                showSearch
                                value={null}
                                placeholder={props.placeholder}
                                style={{ width: 150 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={null}
                                // onChange={(e) => findProjectTasks(e)}
                                notFoundContent={null}
                            >
                                { }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="otherDetails"
                            label="If you selected 'other', please supply details below"
                        >
                            <TextArea rows={2} />
                        </Form.Item>

                        <Form.Item
                            name="subNonStem"
                            label="Academic subjects (non-STEM)"
                        >

                            <Select
                                showSearch
                                value={null}
                                placeholder={props.placeholder}
                                style={{ width: 150 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={null}
                                // onChange={(e) => findProjectTasks(e)}
                                notFoundContent={null}
                            >
                                { }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="stem"
                            label="Academic: STEM"
                        >

                            <Select
                                showSearch
                                value={null}
                                placeholder={props.placeholder}
                                style={{ width: 150 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={null}
                                // onChange={(e) => findProjectTasks(e)}
                                notFoundContent={null}
                            >
                                { }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="lang"
                            label="Languages (please specify)">
                            <Input />
                            <Select>
                                <Option value="beginner">Beginner</Option>
                                <Option value="proficient">Proficient</Option>
                                <Option value="fluent">Fluent</Option>
                                <Option value="nativeSpeaker">Native speaker</Option>
                            </Select>
                            <Button>Add</Button>
                        </Form.Item>
                        <Form.Item
                            name="documentation"
                            label="Documentation/style book familiarity"
                        >
                            <Select>
                                <Option value="male">Trade</Option>
                                <Option value="female">Academic</Option>
                                <Option value="other">Education K-12</Option>
                                <Option value="other">Education post-secondary</Option>
                                <Option value="other">ELL K-12</Option>
                                <Option value="other">ELL post-secondary</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="info"
                            label="Please add any further relevant information (if any)"
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                        <Form.Item
                            name="cv"
                            label="Please click here to upload your CV."
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="sample"
                            label="Please click here to upload an editing sample."
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="info"
                            label="If applying for work in Education K-12 or Education post-secondary,
                            please provide a brief summary"
                        >
                            <TextArea rows={2} />
                        </Form.Item>

                    </Form>
                </Content>
            </Layout>
        </Layout>
    );
}