import React, { useState, useEffect } from 'react';
import backgroundImage from "./img/background.jpeg";

import './App.css';
import './antd.css';
import axios from 'axios';
import store from 'store2';
import { apiRoot } from "./Config";
import { Button, Card, Form, Input, Checkbox, Layout, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'

const { Header, Footer, Content } = Layout;


function Register() {
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const params = useParams();

  useEffect(() => {
    console.log(store.get("freelancer"));
    console.log(params);

    if (params.fuzzyid !== undefined) {
      axios.post(`${apiRoot}submissions/freelancer/public/verify-email/` + params.fuzzyid, {})
        .then(res => {
          window.location.href = window.location.origin;
        }).catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    values["email"] = values["username"];
    values["url"] = window.location.origin;
    axios.post(`${apiRoot}submissions/freelancer/public/user/userregistration`, values)
      .then(res => {
        if (res.data.status == 200) {
          window.location.href = "/userinfo?message=user-registration";
          return;
        }
        message.info(res.data["message"]);
      }).catch(function (error) {
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const privacyPolicy = (e) => {
    if(e.target.checked) { setSubmitDisabled(false); } else { setSubmitDisabled(true); }
  };
  

  return (
    <div className="myfullscreen">
      <Card style={{
      }} id="loginform"
      >
        <div><img width="40%" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>

        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ background: "white" }}>
          <div className="row">
            <label className='rowlabel'>First Name</label>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="row">
            <label className='rowlabel'>Last Name</label>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="row">
            <label className='rowlabel'>Email</label>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </div>
          <div className="row">
            <label className='rowlabel'>Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}>
              <Input type='password' />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 20,
              }}>
              <Checkbox defaultChecked={false} onChange={privacyPolicy} style={{ marginLeft: "7%" }}>I hereby acknowledge that I have read and understood the terms and conditions as provided in the '<a href="https://docs.google.com/document/d/e/2PACX-1vSssehMeX9FNAP534Q-ilH4XaI-wc__B5ASKIsGye90sV3YGatOhuP-KjDwPymJQ6o5Bpiu6VyNCsmY/pub">Terms and Conditions</a>' link and I agree to all of the terms.</Checkbox>
            </Form.Item>
          </div>
          <div id="button" className="row">
            <Form.Item>
              <Button disabled={submitDisabled} type="primary" htmlType="submit">
                REGISTER
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={(e) => window.location.href = "/"} type="primary" htmlType="button">
                LOGIN
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className="myfooter"><a href="https://docs.google.com/document/d/e/2PACX-1vSR8nGaX8FiG9JNheeZG6-4INv9vPh1uStfYqEomhlliL1hCiRFgR2ZCFVVUGMebLmIn7dgi8z6GMob/pub">Privacy Policy</a> | <a href="https://newgen.co/aboutus">Newgen KnowledgeWorks (A Unit of Newgen DigitalWorks Pvt Ltd).

</a></div>
      </Card>
    </div>
  );
}

export default Register;