import React, { useState, useEffect } from 'react';
import backgroundImage from "./img/background.jpeg";

import './App.css';
import './antd.css';
import axios from 'axios';
import store from 'store2';
import { apiRoot } from "./Config";
import { Button, Card, Form, Input, Typography, Layout, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom'
import * as qs from 'query-string';

const { Header, Footer, Content } = Layout;


function Forgot() {

  const params = useParams();

  useEffect(() => {
    console.log(params);
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    var url = window.location.origin + "/reset?token=";
    axios.get(`${apiRoot}submissions/public/password-reset/password-reset-link?emailTemplate=freelancer-forgot-email&email=${values["email"]}&url=${url}`)
      .then(res => {
        if (res.data.status == 200) {
          message.info("We have sent a Password reset link to the mentioned email. Please check your email.");
          return;
        }
        message.info("Unable to send reset password link");
      }).catch(function (error) {
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <div className="myfullscreen">
      <Card style={{
      }} id="loginform"
      >
        <div><img width="40%" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>

        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ background: "white" }}>
            <div className="row">
            <label className='rowlabel'>Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </div>
          <div id="button" className="row">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                REMIND ME
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div id="alternativeLogin">
          <p>You will receive an email to reset your password.</p>
        </div>
      </Card>
    </div>
  );
}

export default Forgot;